@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.title {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: rem(20);
  flex-grow: 1;
  text-align: center;
}

.icon {
  position: absolute;
  padding: spacing(0.6, 1.2);
  width: 28px;
  top: 18px;
  cursor: pointer;
}

.resetButtonFull {
  position: absolute;
  right: 15px;

  @include devices(md-up) {
    position: relative;
    right: 0;
  }
}
