@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  margin-bottom: spacing(5);
  padding: spacing(0, 1);

  @include devices(md-up) {
    padding: spacing(0, 2);
    margin-bottom: spacing(7.5);
  }
}

.text {
  line-height: 1.45;
  margin: 1.25em 0;
}

.signName {
  text-transform: capitalize;
}

.topicHeader {
  font-size: 1.05rem;

  @include devices(sm-up) {
    font-size: 1.2rem;
  }
}
