@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.headline {
  @include devices(md-up) {
    text-align: left;
  }
}

.searchTitle {
  font-size: rem(17);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    font-size: rem(34);
  }
}
