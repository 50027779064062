@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.expertEntry {
  position: relative;

  &:before {
    z-index: 10;
    position: absolute;
    display: block;
    height: 1px;
    background-color: var(--contextual-colors-neutral);
    bottom: 0;
    left: 0;
    right: 0;

    @include devices(md-up) {
      left: spacing(2);
      right: spacing(2);
    }
  }

  &:not(:last-child) {
    margin-bottom: 0;

    &:before {
      content: "";
    }
  }
}

.loader {
  text-align: center;
  padding: spacing(2);
}

.listHeadline {
  background-color: var(--contextual-colors-bg-secondary-accent);
  display: block;
  padding: spacing(1.25, 2);
  font-weight: var(--font-weight-bold);
  font-size: rem(12);
  color: var(--text-secondary);

  @include devices(md-up) {
    background-color: transparent;
    font-weight: var(--font-weight-medium);
    font-size: rem(24);
    color: var(--text-primary);
    padding: 0;
    margin-bottom: spacing(2);
  }
}
