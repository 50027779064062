@use "@styles/partials/functions" as *;

.root {
  padding: spacing(2);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    width: 100%;
  }
}
