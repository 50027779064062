@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
}

.formField {
  text-align: left;
  margin-bottom: spacing(2);

  &:last-child {
    margin-bottom: 0;
  }
}

.submitButton {
  font-size: rem(14);
  @include devices(md-up) {
    font-size: rem(16);
  }
}
