@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.customCardContainer {
  margin-bottom: spacing(2);

  :global(.adyen-checkout__input) {
    height: 50px;
  }
}

.consentAutomaticDebts {
  color: var(--text-secondary);
  margin-bottom: spacing(2);

  @include devices(sm-up) {
    padding-bottom: spacing(3);
    border-bottom: 1px solid var(--grey--a200);
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: spacing(2);

  :global(.adyen-checkout__paypal__button) {
    margin-bottom: 0;
  }

  @include devices(sm-up) {
    flex-direction: row;
    gap: 24px;
  }
}

.paypalButtonWrapper {
  width: 100%;
  max-width: 300px;
}

.paypalButtonWrapperDisabled {
  pointer-events: none;
  opacity: 0.7;
}

.description {
  font-weight: var(--font-weight-medium);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.subtitle2 {
  margin-bottom: 0;
}

.subtitle2Paypal {
  margin-bottom: spacing(2);
}
