@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.text {
  font-size: rem(14);
  line-height: 1.2;
  margin-bottom: spacing(1.5);

  @include devices(sm-up) {
    margin-bottom: spacing(3);
    font-size: rem(16);
  }
}

.settingsWrapper {
  margin-bottom: 0;

  @include devices(sm-up) {
    margin-bottom: spacing(3);
  }
}

.submit {
  margin-top: spacing(2);
}

.callSettings {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: spacing(0, 1.5, 1.5);

  @include devices(md-up) {
    padding: spacing(2, 7.5);
    max-width: 650px;
  }
}
