@use "@styles/partials/functions" as *;

.root {
  position: relative;
  width: 100%;
}

.textField {
  width: 100%;
  :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
    border-width: 1px;

    &:hover fieldset {
      border-width: 1px;
    }
  }
  :global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: var(--primary-main);
  }
}

.input {
  background-color: transparent;
  font-size: rem(14);
  height: 50px;
  padding: spacing(1, 2);
  box-sizing: border-box;
}

.notchedOutline {
  border-color: var(--contextual-colors-ui-secondary);
}

.errorHint {
  display: flex;
  color: var(--error-main);
  font-size: rem(12);
  line-height: 1.6;
  margin: spacing(0.5, 0);
}

.errorHintIcon {
  margin-right: 5px;
}

.indicatorWrapper {
  margin-top: 4px;
}

.rootInput {
  background-color: transparent;
}
