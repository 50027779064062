@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.headline {
  color: var(--text-primary);
  font-size: rem(38);
  font-weight: var(--font-weight-bold);
  line-height: 1.21;
  letter-spacing: 0.5px;
  margin-bottom: spacing(1);

  @include devices(md-down) {
    max-width: 100%;
  }
}

.withSpacing {
  margin-top: spacing(3);
}
