@use "@styles/partials/functions" as *;

.root {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 auto spacing(2);
  max-width: 300px;
  gap: spacing(1);
  list-style: none;
}

.statsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.stars {
  height: 24px;
}

.title {
  font-size: rem(12);
  color: var(--contextual-colors-ui-secondary);
}

.value {
  font-size: rem(16);
  text-transform: capitalize;
  margin-top: spacing(1);
}

.signIcon {
  color: var(--primary-main);
}
