@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.rootPaperBody {
  display: flex;
  padding: spacing(2, 0);
  min-width: 250px;
}

.paperBody {
  padding: spacing(1.5);
  display: flex;
  justify-content: space-between;
  flex: 1;
  background-color: var(--contextual-colors-bg-secondary-accent);
  line-height: 19px;
}

.expertRoot {
  display: flex;
  align-items: flex-start;

  @include devices(md-up) {
    flex-direction: row;
    text-align: left;
    width: 100%;
  }
}

.avatar {
  width: 43px;
  margin-bottom: 0;
  margin-right: spacing(1.5);

  @include devices(md-up) {
    width: 31px;
    margin-right: spacing(1);
  }

  .onlineStatus {
    min-width: 11px;
    min-height: 11px;
    border-width: 1px;
  }
}

.expert {
  display: flex;
  flex-direction: column;
  line-height: 19px;
  font-weight: var(--font-weight-medium);
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: rem(12);

  @include devices(sm-up) {
    font-size: rem(14);
  }
}

.deleteWrapper {
  cursor: pointer;
  color: var(--contextual-colors-ui-secondary);
  margin-left: auto;
}

.actionsWrapper {
  display: flex;
  margin-top: 1px;
  width: 100%;
  justify-content: space-between;
}

.centered {
  align-self: center;
}
