@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.icon {
  width: 64px !important;
  height: 64px !important;
  margin-bottom: spacing(2) !important;

  @include devices(md-up) {
    width: 128px !important;
    height: 128px !important;
  }
}
