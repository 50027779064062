@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  width: auto;
  height: auto;
  padding: spacing(1);

  @include devices(sm-down) {
    width: 100%;
    height: 100%;
  }
}
