@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.test {
  @include devices(sm-up) {
    background-color: darkcyan;
  }
}

.footer {
  background-color: var(--contextual-colors-bg-footer);
  color: var(--common-white);
  padding: spacing(2, 0);
  position: relative;
  z-index: 1;
  min-height: 250px;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .navLink:hover {
    color: var(--text-secondary);
  }

  .socialLink:hover {
    color: var(--primary-dark);
  }

  .navLink,
  .socialLink {
    transition: color 0.1s;
  }

  .navLinksWrapper,
  .socialLinksWrapper,
  .logoWrapper {
    @include devices(md-up) {
      flex: 1;
    }
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include devices(md-up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.navLinksWrapper {
  margin-top: spacing(2);
  @include devices(md-up) {
    margin-top: spacing(3);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }
}

.navLinks {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @include devices(md-up) {
    align-items: flex-start;
    flex-direction: column;
    margin-right: spacing(2);
  }
}

.navLink {
  font-size: rem(14);
  line-height: 1.57;
  margin: spacing(1);
  @include devices(md-up) {
    line-height: 2;
  }
}

.socialLinksWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include devices(md-up) {
    margin-top: spacing(5.625);
  }
}

.socialLinks {
  color: var(--primary-main);
  display: flex;
  flex-direction: row;
  @include devices(md-up) {
    flex-wrap: wrap;
    max-width: 108px;
  }
}

.socialLink {
  justify-self: end;
  line-height: 1;
  margin: spacing(1);

  > a {
    font-size: 0;
  }
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: 1;
  @include devices(md-up) {
    order: 0;
  }
}

.logo {
  display: none;
  margin: spacing(3.75, 1, 2, 1);
  opacity: 0.7;

  @include devices(md-up) {
    display: block;
    margin: spacing(8, 1, 2.375, 1);
  }
}

.illustration {
  opacity: 0.7;
  font-size: rem(12);
  line-height: 2.33;
  letter-spacing: 0.26px;
  text-align: center;
  margin: spacing(0.875, 4, 2, 4);
  @include devices(md-up) {
    margin: spacing(0.375, 4, 5.625, 4);
  }
}
