@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

@function calcColumnWidth($columns, $gap: 2) {
  @return calc(100% / $columns - spacing($gap * ($columns - 1) / $columns));
}

.root {
  padding: spacing(0, 1);

  @include devices(md-up) {
    padding: spacing(0, 2);
  }
}

.linkBoxCommon {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2);

  .linkItem {
    font-size: rem(12);
    width: calcColumnWidth(2);

    &:nth-child(n + 31) {
      display: none;
    }
  }

  @include devices(md-up) {
    .linkItem {
      font-size: rem(14);
      width: calcColumnWidth(3);
    }
  }

  @include devices(lg-up) {
    .linkItem {
      width: calcColumnWidth(5);
    }
  }
}

.linkBox {
  display: flex;

  .linkItem {
    margin-bottom: spacing(2.125);
    font-size: rem(12);
  }

  @include devices(md-up) {
    justify-content: space-between;

    .linkItem {
      margin-bottom: spacing(1.875);
      font-size: rem(14);
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 50%;

  @include devices(md-up) {
    width: auto;
  }
}

.headline {
  font-size: rem(18);
  margin-bottom: spacing(1.75);

  @include devices(md-up) {
    font-size: 1.2rem;
  }
}

.link {
  color: var(--primary-main);
}
