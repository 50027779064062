@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  padding: spacing(1.75, 2);
  background-color: var(--common-white);
  width: 100%;
  border-radius: 4px;
  display: flex;
  margin-bottom: spacing(2);
}

.avatarWrapper {
  width: 80px;
  height: 100px;
  margin: spacing(0, 1.25, 1.25, 0);
  float: left;

  @include devices(sm-up) {
    margin: spacing(0, 1.5, 0, 0);
  }

  @include devices(md-up) {
    float: none;
    width: 80px;
    height: 80px;
  }
}

.avatarSkeleton {
  min-height: 100%;
}

.detailsCol {
  flex: 1;
}

.text {
  margin-top: 1px;
  height: 16px;
}
