@use "@styles/partials/breakpoints" as *;

.chipsNavigation {
  @include devices(sm-up) {
    display: none;
  }
}

.sideNavigation {
  display: none;

  @include devices(sm-up) {
    display: block;
  }
}
