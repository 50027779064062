@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  padding-top: spacing(6);
  text-align: initial;
}

.title {
  font-size: rem(24);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: spacing(3);
  text-align: center;

  @include devices(md-up) {
    font-size: rem(30);
  }
}
