@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  padding-left: spacing(4);
  padding-right: spacing(4);
  border-radius: 5px;
  white-space: nowrap;
}

.actionLimited {
  background-color: var(--primary-main);
  color: var(--common-white);
  padding-top: spacing(1.1);
  padding-bottom: spacing(1.1);
  height: 100%;
  border-radius: 34px;
  font-size: rem(16);
  margin-top: spacing(0.5);

  @include devices(md-up) {
    padding-top: spacing(0);
    padding-bottom: spacing(0);
    border-radius: 0;
    width: 160px;
    min-width: 120px;
    font-size: rem(20);
    margin-top: 0;
  }
}

.actionFull {
  padding-top: spacing(1.5);
  padding-bottom: spacing(1.5);

  @include devices(md-up) {
    border-radius: 40px;
    font-size: rem(16);
  }
}
