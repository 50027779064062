@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.appBar {
  z-index: 0;
  height: spacing(8);
}

.container {
  flex: 1;
  display: flex;
  align-items: center;

  @include devices(sm-up) {
    justify-content: space-between;
  }
}

.logoWrapper {
  display: flex;
  width: 124px;
  height: 38px;

  @include devices(sm-up) {
    width: 155px;
    height: 31px;
  }
}

.rightSlotWrapper {
  display: none;

  @include devices(sm-up) {
    display: block;
  }
}
