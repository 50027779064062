@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.spacer {
  @include devices(md-up) {
    margin: spacing(0, 0.5);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include devices(lg-up) {
    margin: spacing(0, 0.75);
  }
}

.logo {
  @include devices(lg-up) {
    margin-right: 42px;
  }
}

.inner {
  position: relative;
  display: inline-flex;
  flex: 1;
  align-items: center;
  height: 100%;
}

.navigationMenu {
  flex: 1;
  height: 100%;
}

.navigationMenuHidable {
  @include devices(md-up) {
    visibility: hidden;
  }

  @include devices(lg-up) {
    visibility: visible;
  }
}

.searchContainer {
  @include devices(md-up) {
    width: 36px;
  }

  @include devices(lg-up) {
    min-width: 188px;
    max-width: 325px;
  }
}

.searchContainerOpened {
  position: relative;

  @include devices(md-up) {
    position: absolute;
    width: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @include devices(lg-up) {
    position: relative;
    transform: none;
    top: auto;
    right: auto;
  }
}

.searchContainerOpenedExtraSpace {
  @include devices(md-up) {
    width: calc(100% - 28px);
  }
}
