@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.footer {
  position: relative;
  background-color: var(--common-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.15);
}

.buttonWrapper {
  padding: spacing(1.5, 3);
  text-align: center;
}

.button {
  max-width: 400px;
}

.iconClassName {
  margin-right: 0;
}

.buttonText {
  font-weight: var(--font-weight-bold);

  @include devices(md-down) {
    font-size: rem(14);
  }
}
