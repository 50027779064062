@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.modalRoot.modalRoot {
  align-items: flex-end;

  @include devices(sm-up) {
    align-items: center;
  }
}

.modal {
  height: auto !important;
  width: 100%;
  max-height: none;

  @include devices(sm-up) {
    max-width: 400px;
  }
}

.modalAsSnackbar {
  position: fixed;
  right: spacing(2);
  left: spacing(2);
  top: auto;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

  @include devices(sm-up) {
    max-width: 400px;
    left: auto;
    right: spacing(3);
  }
}

.backdrop {
  display: none;
}
