@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.cta {
  bottom: 0;
  padding: spacing(1, 3.5);

  @include devices(md-up) {
    padding: spacing(1.5, 6.5);
  }
}

.ctaLabel {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);

  @include devices(md-up) {
    font-size: rem(22);
  }
}
