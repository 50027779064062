@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.list {
  list-style: none;

  :global(.slick-slider) {
    max-width: 100%;
  }

  :global(.slick-list),
  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    padding: spacing(0, 0.5);
    display: flex;

    &:not(:global(.slick-active)) {
      opacity: 0.5;
    }

    & > div {
      display: flex;
    }
  }
}

.listItem {
  display: flex !important;
  flex: 1;
}

.button {
  height: 28px;
  width: 28px;
  padding: 0;
  position: absolute;
  top: calc(50% - 22px);
  left: -42px;
  z-index: 10;
  background-color: var(--primary-main);
  border: none;
  border-radius: 50%;
  color: var(--common-white);
  line-height: 0;
  outline: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--primary-dark);
    cursor: pointer;
  }

  @include devices(md-down) {
    left: 0;
  }
}

.nextButton {
  transform: rotate(180deg);
  left: auto;
  right: -42px;
  @include devices(md-down) {
    right: 0;
  }
}
