@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.title {
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  font-size: rem(16);
  margin-bottom: spacing(1.5);

  @include devices(sm-up) {
    font-size: rem(20);
  }
}

.subtitle1 {
  font-weight: var(--font-weight-bold);
  font-size: rem(16);
  margin-bottom: spacing(1.5);
  line-height: 1.2;

  @include devices(sm-up) {
    font-size: rem(18);
  }
}

.subtitle2 {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
  margin-bottom: spacing(1.5);
  line-height: 1.2;

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.body {
  line-height: 1.2;
}

.warning {
  color: var(--contextual-colors-attention);
}
