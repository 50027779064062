@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 12px;
}

.bar {
  background-color: var(--contextual-colors-neutral);
  border-radius: 3px;
  display: block;
  height: 4px;
  margin-right: 2px;
  flex-shrink: 0;
  flex-grow: 2;
  flex-basis: 0;
  transition: background-color 0.3s;
  @include devices(sm-up) {
    margin-right: 4px;
  }

  &.weak:first-child,
  &.short:first-child {
    background-color: var(--contextual-colors-brand-secondary);
  }

  &.normal:nth-child(-n + 2) {
    background-color: var(--contextual-colors-attention);
  }

  &.strong {
    background-color: var(--contextual-colors-icons-success);
  }
}

.message {
  color: inherit;
  flex-grow: 3;
  flex-basis: 0;
  font-size: rem(12);
  font-weight: 500;
  line-height: 1.3;
  white-space: nowrap;
  transition: color 0.3s;
  padding-left: spacing(1);

  &.weak {
    color: var(--contextual-colors-brand-secondary);
  }

  &.normal {
    color: var(--contextual-colors-attention);
  }

  &.strong {
    color: var(--contextual-colors-icons-success);
  }
}
