@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  @include devices(sm-up) {
    background-color: var(--common-white);
    border-radius: 4px;
  }
}

.scrollerWrapper {
  position: relative;
}

.withLighterBefore {
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(
      90deg,
      var(--common-white),
      hsla(0, 0%, 100%, 0)
    );
    width: 20px;
    height: 100%;
  }
}

.withLighterAfter {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      var(--common-white)
    );
    width: 20px;
    height: 100%;
  }
}

.scroller {
  max-height: calc(100vh - 131px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include devices(sm-down) {
    display: flex;
    flex-direction: row;
  }
}

.childNavItem {
  padding: spacing(1, 1.5, 1.125);
  color: var(--text-primary);
  font-size: rem(14);
  background-color: var(--common-white);
  margin-right: spacing(1);
  display: inline-block;
  border-radius: 4px;

  > a {
    white-space: nowrap;
    display: block;
    width: 100%;
  }

  @include devices(sm-up) {
    height: 28px;
    display: flex;
    align-items: center;
    margin: spacing(2, 0, 2, 5.375);
    font-size: rem(14);
    color: var(--text-primary);
    cursor: pointer;
  }
}

.category {
  display: flex;
  align-items: center;
  font-size: rem(16);
  color: var(--text-secondary);

  @include devices(sm-up) {
    padding: spacing(1.875, 4.625, 1.75);
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid var(--contextual-colors-neutral);
  }
}

.rounded {
  border-radius: 4px 4px 0 0;
}

.navItem {
  padding: spacing(1, 1.5, 1.125);
  color: var(--text-primary);
  font-size: rem(14);
  background-color: var(--common-white);
  margin-right: spacing(1);
  display: inline-block;
  border-radius: 6px;

  > a {
    white-space: nowrap;
    display: block;
    width: 100%;

    &:hover {
      color: var(--primary-main);
      transition: color 0.3s ease;
    }
  }

  @include devices(sm-up) {
    padding: spacing(0.5, 0);
    display: flex;
    align-items: center;
    margin: spacing(2) spacing(0) spacing(2) spacing(4.625);
    font-size: rem(14);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    cursor: pointer;
  }
}

.rectangle {
  width: 5px;
  height: 20px;
  background-color: var(--primary-main);
  margin-right: spacing(2);
  margin-left: -21px;
  border-radius: 2.5px;
  flex-shrink: 0;
}

.arrow {
  margin-left: auto;
  margin-right: spacing(2);
  display: flex;
}

.activeMobileSideNavItem {
  @include devices(sm-down) {
    background-color: var(--action-selected);
    border: 1px solid var(--primary-main);
    color: var(--primary-main);
    font-weight: var(--font-weight-medium);
  }
}

.tag {
  margin-left: spacing(1);
}
