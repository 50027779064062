@use "@styles/partials/functions" as *;

.icon {
  font-size: 0;
  color: var(--contextual-colors-icons-success);
}

.link {
  color: var(--contextual-colors-brand-primary);
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
}
