@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.text-align--left {
  text-align: left;
}

.text-align--center {
  text-align: center;
}

.text-align--right {
  text-align: right;
}

.text-align--justify {
  text-align: justify;
}

.text-align--inherit {
  text-align: inherit;
}

.margin-bottom--small {
  margin-bottom: 0;

  @include devices(sm-up) {
    margin-bottom: spacing(2);
  }
}

.margin-bottom--medium {
  margin-bottom: spacing(3);
}

.margin-bottom--big {
  margin-bottom: spacing(6);

  @include devices(sm-up) {
    margin-bottom: spacing(7.5);
  }
}

.color-white {
  color: var(--common-white);
}

.spacingsRegular {
  padding: spacing(1.5, 0);
  margin: spacing(0, 1.5);

  @include devices(md-up) {
    padding: spacing(2, 7.5);
    margin: 0;
  }
}

.spacingsBig {
  padding-top: spacing(2);
  padding-bottom: spacing(2);
}

.card {
  background-color: var(--common-white);
  border-radius: 8px;
  padding: spacing(2);
}
