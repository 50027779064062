@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

$avatarImageSizes-mobile-width: 80px;
$avatarImageSizes-mobile-height: 100px;
$avatarImageSizes-desktop-width: 160px;
$avatarImageSizes-desktop-height: 200px;

.wrapper {
  background-color: var(--common-white);
  border-radius: 4px;
  overflow: hidden;

  @include devices(md-up) {
    display: flex;
  }
}

.details {
  flex: 1;
  display: block;
  padding: spacing(1);

  @include devices(sm-up) {
    display: flex;
    padding: spacing(2);
  }
}

.caption {
  display: flex;
  align-items: center;
  margin-top: spacing(0.25);
  margin-bottom: spacing(1.25);
  flex-wrap: wrap;

  @include devices(md-up) {
    margin-bottom: spacing(0.875);
  }
}

.expertName {
  color: var(--text-primary);
  font-weight: var(--font-weight-bold);
  font-size: rem(16);
  line-height: 23px;
  margin-right: spacing(1);

  @include devices(md-up) {
    font-size: rem(20);
    line-height: 28px;
    margin-right: spacing(2);
  }
}

.descriptionWrapper {
  @include devices(md-up) {
    margin: spacing(1.75, 0, 0);
  }
}

.titleDescription {
  color: var(--text-secondary);
  clear: both;
  overflow: hidden;
  -webkit-line-clamp: 3;

  @include devices(md-up) {
    -webkit-line-clamp: 2;
  }
}

.avatarWrapper {
  width: $avatarImageSizes-mobile-width;
  height: auto;
  margin: spacing(0, 1.25, 1.25, 0);
  float: left;

  @include devices(sm-up) {
    margin: spacing(0, 3.75, 1.25, 0);
  }

  @include devices(md-up) {
    float: none;
    margin: spacing(0, 7.5, 0, 0);
    width: $avatarImageSizes-desktop-width;
    height: auto;
  }
}

.productsRoot {
  border-top: 1px solid var(--contextual-colors-neutral);
  padding: spacing(1, 1, 2.5);

  @include devices(md-up) {
    justify-content: center;
    padding: spacing(2.75);
    max-width: 290px;
    border-top: 0;
    border-left: 1px solid var(--contextual-colors-neutral);
  }
}

.detailsCol {
  flex: 1;
}

.profileLink {
  color: var(--primary-main);
  display: none;
  font-weight: var(--font-weight-bold);
  margin-top: spacing(0.375);

  & svg {
    width: 14px;
    height: 11px;
    margin-left: spacing(0.375);
    transform: rotate(180deg);
  }

  @include devices(md-up) {
    display: block;
    font-size: 1rem;
    margin-top: spacing(2);

    & svg {
      width: 18px;
      height: 14px;
      margin-left: spacing(0.625);
    }
  }
}

.productsList {
  @include devices(md-up) {
    flex-direction: column;
  }
}
