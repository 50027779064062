@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

$hover-over-white-color: rgba(0, 0, 0, 0.04);

.root {
  display: flex;
  list-style: none;
  padding: 20px 0;
  margin: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include devices(md-up) {
    justify-content: normal;
    padding: 0;
  }
}

.item {
  display: block;
  margin-left: spacing(2);

  &:first-child {
    margin-left: 0;
  }

  &:first-child {
    > a {
      font-weight: var(--font-weight-medium);
    }
  }
}

.buttonTypography {
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  line-height: 1.5;

  @include devices(md-up) {
    white-space: nowrap;
    font-weight: var(--font-weight-medium);
    font-size: rem(16);
  }
}

.borderedButton {
  display: block;
  border: 1px solid;
  color: inherit !important;
  border-radius: 30px;
  padding: spacing(0.625, 2);
  min-width: 120px;
  transition: background-color 0.15s;

  &:hover {
    border-width: 1px;
    color: var(--secondary-main) !important;
    background-color: $hover-over-white-color;
  }

  @include devices(md-up) {
    min-width: auto;
  }
}
