@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.noActiveMethodDescription {
  padding: spacing(1.5);
  background-color: var(--contextual-colors-bg-secondary-accent);
  line-height: 1.35;

  @include devices(sm-up) {
    padding: 0;
    background-color: unset;
  }
}
