@use "@styles/partials/functions" as *;

.root {
  display: flex;
  margin-bottom: spacing(1);
  padding: spacing(1.5, 1.75, 1);
  font-size: rem(12);
  line-height: 1.3;
}

.error {
  color: var(--error-main);
  background-color: var(--contextual-colors-bg-error);
}

.success {
  color: var(--success-main);
  background-color: var(--contextual-colors-bg-secondary-accent);
}

.info {
  color: var(--text-secondar);
  background-color: var(--contextual-colors-bg-secondary-accent);
}

.warning {
}

.banner {
}

.icon {
  margin-right: 5px;
}

.link {
  color: var(--primary-main);
}
