@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.orText {
  font-weight: var(--font-weight-medium);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.disabledBox {
  opacity: 0.4;
  pointer-events: none;
}
