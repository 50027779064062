@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  padding: spacing(4, 3);
  position: relative;

  @include devices(lg-up) {
    padding: spacing(4, 4.5);
  }

  &::before {
    content: "";
    height: 1px;
    background-color: var(--contextual-colors-neutral);
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
  }
}

.text {
  color: var(--text-primary);
  text-align: center;
  font-size: rem(17);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.title {
  font-size: rem(19);
  margin: 0;
  margin-bottom: spacing(1.5);
}

.description {
  font-size: rem(17);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(4);
}

.categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.categoriesExtended {
  justify-content: space-around;
}

.category {
  height: 125px;
  width: 80px;
  border-radius: 4px;
  align-items: flex-end;
  justify-content: center;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid var(--common-white);

  &:before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.04%, rgba(0, 0, 0, 0.7) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
  }

  @include devices(s-up) {
    height: 138px;
    width: 92px;
  }

  @include devices(sm-up) {
    height: 142px;
    width: 102px;
  }
}

.link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10;
}

.categoryHeadline {
  color: var(--common-white);
  font-size: rem(12);
  font-weight: var(--font-weight-medium);
  text-align: center;
  z-index: 10;

  @include devices(sm-up) {
    font-size: rem(14);
  }
}
