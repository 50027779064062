@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;
@use "./variables.module.scss" as *;

.root {
  position: relative;
  width: 100%;
  overflow: hidden;

  @include devices(md-up) {
    height: auto;
    margin-top: spacing(5);
  }
}

.header {
  display: flex;
  padding: 15px 0px 10px 0px;
  justify-content: center;
  height: $HEADER_HEIGHT;
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid var(--contextual-colors-neutral);
  background-color: var(--common-white);

  @include devices(md-up) {
    display: none;
  }
}

.tabsWrapper {
  position: fixed;
  top: $HEADER_HEIGHT;
  width: 100%;
  overflow-y: hidden;
  height: calc(100% - $HEADER_HEIGHT);

  @include devices(md-up) {
    position: relative;
    top: initial;
  }
}

.tabsWrapperWithFooter {
  height: calc(100% - $HEADER_HEIGHT - $FOOTER_HEIGHT);
}

.tabBody {
  height: calc(100% - $TAB_HEADER_HEIGHT);
  overflow-y: auto;
  width: 100%;

  @include devices(md-up) {
    height: auto;
  }
}

.backIcon {
  padding: spacing(0.6, 1.2);
  top: 11px;
  width: 28px;
  position: absolute;
  left: 6px;
  cursor: pointer;
  color: var(--text-primary);
}

.headerText {
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
}

.footerLink {
  padding-top: spacing(1);
  padding-bottom: spacing(1);
}

.footerClass {
  position: fixed;
  bottom: 0;
  width: 100%;
}
