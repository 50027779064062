@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.absolutelyCentered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @include devices(md-up) {
    position: static;
    transform: none;
    left: auto;
  }
}

.logo {
  padding: spacing(1);
  margin-left: spacing(-0.5);
  width: 124px;
  height: 38px;
  flex-shrink: 0;

  @include devices(sm-up) {
    padding: spacing(0);
    margin-left: spacing(0);
    width: 155px;
    height: 31px;
  }
}
