@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.dateText {
  font-size: rem(12);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  padding-right: spacing(2);

  @include devices(sm-up) {
    font-size: rem(14);
  }
}
