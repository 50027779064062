@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.mobile {
  @include devices(md-up) {
    display: none;
  }
}

.desktop {
  display: none;

  @include devices(md-up) {
    display: block;
  }
}
