@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  padding: spacing(1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.wrapperSimple {
  background-color: transparent;
  min-height: spacing(5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(1, 1.5);
}

.text {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  line-height: normal;
  text-align: center;

  @include devices(md-up) {
    font-size: rem(18);
  }
}

.textSimple {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.iconWrapper {
  height: spacing(2.5);
  margin-right: spacing(1.5);
  position: relative;
}

.giftIcon {
  height: 17px;
  width: 17px;
  flex-shrink: 0;
  color: var(--common-white);
  margin-right: spacing(0.5);

  @include devices(md-up) {
    margin-right: spacing(1);
    width: 19px;
    height: 19px;
  }
}
