@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  width: 100%;
  padding-left: spacing(0);
  padding-right: spacing(0);

  @include devices(md-up) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: spacing(1, 2);

  @include devices(md-up) {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.button {
  color: var(--text-primary);
  background-color: transparent;
  border-radius: 5px;
  padding: spacing(0.75, 1.5);
  text-transform: capitalize;
  border-color: var(--primary-main);
  width: 100%;
  font-weight: var(--font-weight-regular);
  font-size: rem(16);
  border-width: 0;

  &:first-of-type {
    @include devices(md-up) {
      border-left-width: 1px;
      border-right-color: transparent;
    }
  }

  &:not(:first-of-type):not(:last-of-type) {
    @include devices(md-up) {
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  &:not(:first-of-type) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @include devices(md-up) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:hover {
        border-left-color: transparent !important;
      }
    }
  }

  &:not(:last-of-type) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @include devices(md-up) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border-right-color: transparent !important;
      }
    }
  }

  &:last-of-type {
    @include devices(md-up) {
      border-right-width: 1px;
      border-left-color: transparent;
    }
  }

  @include devices(md-up) {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
}

.pageConfig {
  @include devices(md-up) {
    border-radius: 20px;
  }
}

.buttonActive {
  color: var(--common-white);
  background-color: var(--primary-main);
  font-weight: var(--font-weight-bold);
  transition: font-weight 0s; // fix for transition queue

  &:hover {
    background-color: var(--primary-main);
  }
}
