@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  margin-top: spacing(-2);
}

.feedbackWrapper {
  padding-top: spacing(2);
}

.inputWrapper {
  width: 100%;

  @include devices(sm-up) {
    max-width: 740px;
  }
}

.input {
  width: 100%;
  padding: spacing(1.5);
  border: 1px solid var(--contextual-colors-ui-secondary);
  border-radius: 4px;
  font-family: inherit;
  font-size: rem(14);
  font-weight: var(--font-weight-regular);
  outline: none;

  &::placeholder {
    font-weight: var(--font-weight-regular);
    font-size: rem(14);
  }

  &:focus {
    border-color: var(--contextual-colors-brand-primary);
  }

  &.error {
    border-color: var(--error-main);
  }
}

.textarea {
  min-height: 150px;
  resize: none;
  overflow: hidden;

  @include devices(md-up) {
    overflow: auto;
    max-height: 250px !important;
  }
}

.errorLength {
  color: var(--error-main);
}

.infoText {
  font-size: rem(14);
  font-weight: var(--font-weight-regular);
  color: var(--contextual-colors-ui-secondary);
}
