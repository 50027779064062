@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.showMore {
  margin: spacing(3) auto spacing(5.5) auto;

  @include devices(sm-up) {
    max-width: 50%;
    margin: spacing(2) auto spacing(7) auto;
  }
}

.listItem {
  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
}

.loadMoreButton {
  border-width: 2px;

  &:hover {
    border-width: 2px;
  }
}
