@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.rootErrorSection {
  margin-bottom: spacing(1);
}

.paperRoot {
  gap: spacing(2);
  padding: spacing(3, 1.5);

  &:not(:last-child) {
    margin-bottom: spacing(1);
  }
}

.paperBody {
  gap: spacing(2);
  padding: 0;
}

.paperCustomHeader {
  margin-bottom: spacing(2);
  text-align: center;
  font-size: rem(19);
}

.errorBody {
  margin-bottom: 0;
  gap: spacing(2);
}

.reason {
  font-size: rem(19);
  font-weight: inherit;
}

.text {
  font-size: rem(16);
  font-weight: inherit;
}

.icon {
  width: 65px;
  height: 65px;
  margin-bottom: 0;
}
