@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.starWrapper {
  transform: scale(1);
  transition: none;

  svg {
    width: 42px;
    height: 42px;
  }
}

.ratingText {
  overflow: hidden;
  align-self: stretch;
  color: var(--contextual-colors-icons-rating);

  &:before,
  &:after {
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    background-color: var(--contextual-colors-neutral);
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }

  &.hidden {
    visibility: hidden;
  }
}
