@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.listWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  @include devices(md-up) {
    flex-wrap: nowrap;
  }
}

.item {
  text-align: center;
  padding-left: spacing(8);
  padding-right: spacing(8);
  margin-bottom: spacing(4);
  flex: 1;

  @include devices(md-up) {
    margin-bottom: 0;
  }
}

.title {
  margin: 0;
  font-size: rem(16);
  margin-top: spacing(3);
  margin-bottom: spacing(2);
}

.text {
  margin: 0;
  font-size: rem(16);
}

.image {
  height: 74px;
  width: auto;
}
