@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.labelLimited {
  font-size: rem(14);
}

.labelFull {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  color: var(--common-black) !important;
  margin-bottom: spacing(2);
}

.radioLabel {
  font-size: rem(15);
  font-weight: var(--font-weight-regular);
  color: var(--text-primary);
}

.radio {
  svg {
    width: 16px;
    height: 16px;
  }
}

.headingClassName {
  font-size: rem(18);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.summaryClassName {
  padding: 0;
  border-bottom: 1px solid var(--contextual-colors-ui-secondary);
  padding-left: spacing(1);
  padding-right: spacing(1);
}

.panelClassName {
  background-color: transparent;
}

.detailsClassName {
  background-color: transparent;
  padding: 0;
}

.detailsWrapperClassName {
  width: 100%;
}

.radioGroup {
  max-height: 240px;
  overflow-y: auto;
  flex-wrap: nowrap;
}
