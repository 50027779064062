@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.switchButton {
  background-color: var(--common-white);
  color: var(--text-primary);
  font-family: inherit;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--contextual-colors-ui-secondary);
  transition: all 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: spacing(1, 2);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  height: 50px;
  margin: 0;

  @include devices(sm-up) {
    font-size: rem(16);

    &:hover {
      color: var(--contextual-colors-brand-primary-hover);
    }
  }
}

.switchButtonActive {
  background-color: var(--action-selected);
  color: var(--primary-main);
  border-color: var(--primary-main);

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-main);
  }
}

.switchButtonRadio {
  padding-left: 0;
}

.radioLabel {
  font-size: rem(14);
  line-height: 19px;
  font-weight: var(--typography-fontWeightMedium);

  @include devices(md-up) {
    font-size: rem(16);
    line-height: 21px;
  }
}
