@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  text-align: center;
}

.row {
  margin-bottom: spacing(2);
}

.avatar {
  max-width: 90px;
  margin-left: auto;
  margin-right: auto;

  @include devices(md-up) {
    max-width: 120px;
  }
}

.displayName {
  font-size: rem(20);
  font-weight: var(--font-weight-bold);
}

.price {
  display: flex;
}

.productsRoot {
  z-index: 10;
  padding-top: spacing(2);

  @include devices(md-up) {
    padding-top: 0;
    margin-top: spacing(2);

    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.productPrice {
  display: block;
}

.promotion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacing(2.5, 2, 1.5, 2);
  border-top: 1px solid var(--contextual-colors-neutral);
  border-bottom: 1px solid var(--contextual-colors-neutral);
}

.divider {
  max-width: 288px;
  margin: spacing(2) auto spacing(2);
  height: 1px;
  background-color: var(--grey--a200);
}

.productsList {
  flex-direction: column;
}
