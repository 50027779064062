@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;
@use "./variables.module.scss" as *;

.toastMessage {
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 52px;
  position: absolute;
  z-index: 100;
}

.toastMessageMobile {
  position: fixed;
}

.withFooter {
  bottom: $FOOTER_HEIGHT;

  @include devices(md-up) {
    bottom: $FOOTER_HEIGHT_DESKTOP;
  }
}
