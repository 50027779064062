@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.sliderWrapper {
  padding-left: spacing(1);
  padding-right: spacing(1);
}

.wrapperLimited {
  height: 100%;
  align-self: center;
  padding-left: spacing(2);
  padding-right: spacing(2);
  margin-top: spacing(1);
  margin-bottom: spacing(0.5);

  @include devices(md-up) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

.wrapperFull {
  @include devices(md-down) {
    padding: spacing(2, 2, 2, 1);
    border-bottom: 1px solid var(--contextual-colors-ui-secondary);
  }
}

.labelLimited {
  font-size: rem(12);

  @include devices(md-up) {
    font-size: rem(14);
    margin-top: spacing(1.75);
  }
}

.labelFull {
  font-size: rem(12);
  color: var(--text-secondary);
  margin-bottom: spacing(0.5);

  @include devices(md-up) {
    font-size: rem(16);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    margin-bottom: spacing(2);
  }
}

.mark {
  background-color: transparent;
}

.markLabel {
  top: 19px;
  color: var(--text-primary);
  font-size: rem(12);
}

.markLabelFull {
  transform: translate(-50%, 4px);
}

.filter {
  height: 2px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 18px;

  @include devices(md-up) {
    padding-bottom: 18px;
  }
}

.sliderTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.gratisCheckbox {
  padding: 0;
  margin-top: spacing(-1.5);
  margin-right: 0;
}
