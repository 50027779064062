@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.customAmount {
  :global(.Mui-disabled.Mui-disabled) {
    background-color: transparent;
  }
}

.orText {
  font-weight: var(--font-weight-medium);

  @include devices(sm-up) {
    font-size: rem(16);
    font-weight: var(--font-weight-bold);
    text-align: center;
  }
}

.numberField {
  @include devices(sm-up) {
    width: calc(100% / 3 * 2);
  }
}

.adornment {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.customAmountSelected {
  & :global(.Mui-disabled),
  & input:disabled {
    background-color: transparent;
  }

  & fieldset {
    border-color: var(--primary-main);
  }
}
