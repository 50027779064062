@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  padding-top: 20px;

  @include devices(sm-up) {
    padding-top: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
}

.title {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 20px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chip {
  border-radius: 5px;
  margin-right: spacing(1);
  background-color: var(--contextual-colors-bg-primary-accent);

  &:focus {
    background-color: var(--contextual-colors-bg-primary-accent);
  }
}

.chipActive {
  background-color: var(--contextual-colors-ui-secondary);

  &:focus {
    background-color: var(--contextual-colors-ui-secondary);
  }
}
