@use "@styles/partials/functions" as *;

.modalWrapper {
  height: auto;
  padding: spacing(0, 2, 4);
  margin: spacing(0, 2);
}

.closeAction {
  padding: spacing(1);
  position: absolute;
  width: 36px;
  height: 36px;
  top: spacing(1);
  right: spacing(1);
  box-shadow: none;
}
