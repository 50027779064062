@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  flex: 1 0 auto;
  margin-top: spacing(3);
  margin-bottom: spacing(3);

  @include devices(lg-up) {
    padding: spacing(0);
  }
}

.introWrapper {
  display: none;
  background-color: var(--common-white);
  padding-top: spacing(2);
  padding-bottom: spacing(4);

  @include devices(md-up) {
    display: block;
  }
}

.introListWrapper {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
