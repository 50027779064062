@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paper {
  margin-bottom: spacing(2);
}

.paperBody {
  @include devices(md-up) {
    padding: spacing(4, 0);
  }
}

.icon {
  width: 50px;
  height: 50px;

  @include devices(md-up) {
    width: 54px;
    height: 54px;
  }
}

.title {
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.button {
  height: 36px;
  background-color: var(--primary-main);
  color: var(--common-white);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(14);

  @include devices(md-up) {
    width: 300px;
    height: 40px;
    border-radius: 33px;
    font-size: rem(16);
  }
}
