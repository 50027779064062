@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.header {
  margin-bottom: 64px;

  @include devices(tablet) {
    margin-bottom: 66px;
  }
}

.noMargin {
  margin-bottom: 0;
}
