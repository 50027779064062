@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.mainSection {
  position: relative;
  background-color: var(--common-white);
  margin: 1px 0;

  @include devices(sm-up) {
    margin: spacing(1.5, 0);
  }
}

.icon {
  margin-right: spacing(1.5);
}

.headline {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  line-height: 23px;
  margin-bottom: spacing(1);

  @include devices(sm-up) {
    font-size: rem(19);
    line-height: 25px;
  }
}

.content {
  font-weight: var(--font-weight-regular);
  font-size: rem(14);
  line-height: 19px;
  margin-bottom: spacing(1.5);

  &:last-child {
    margin-bottom: 0;
  }
}

.signSection {
  display: flex;
  margin-bottom: spacing(3);
}

.signText {
  margin-left: spacing(1);
}

.sign {
  font-weight: var(--font-weight-bold);
  font-size: rem(16);
  line-height: 23px;

  @include devices(sm-up) {
    font-size: rem(19);
    line-height: 25px;
  }
}

.signDate {
  line-height: 19px;
}

.paper {
  padding: 0;
}

.paperExperts {
  @include devices(sm-up) {
    margin-bottom: spacing(1.5);
  }
}

.paperHoroscopeBody {
  padding: spacing(0, 1.5, 2);
}

.expertsCaption {
  font-weight: var(--font-weight-bold);
  font-size: rem(20);
  line-height: 31px;
  margin-bottom: spacing(3);

  @include devices(sm-up) {
    font-size: rem(24);
  }
}

.line {
  height: 1px;
  background-color: var(--grey--a200);
}

.paperExpertsBody {
  padding: spacing(0, 0, 2, 1.5);
}

.astroWave {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
}

.astroIcon {
  position: absolute;
  top: 30%;
  right: 30%;
}

.feedback {
  font-weight: var(--font-weight-regular);
  font-size: rem(13);
  line-height: 17px;
  position: relative;
  max-width: 416px;
  z-index: 10;
  margin-top: spacing(1);
}

.title {
  background-color: var(--common-white);
}

.titleLink {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
  line-height: 1.3;
  text-decoration: underline;

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.feedbackButtonSection {
  padding-bottom: spacing(2);
}

.feedbackButton {
  height: 36px;
  font-weight: var(--font-weight-bold);
  margin-top: spacing(5.5);
  line-height: 24px;
  z-index: 10;
}

.feedbackButtonLabel {
  font-size: rem(14);
}

.birthday {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  line-height: 18px;
  color: var(--contextual-colors-ui-secondary);
}

.question {
  margin-right: spacing(1);
  display: flex;
  align-items: center;

  @include devices(sm-up) {
    cursor: pointer;
  }
}

.readMoreSection {
  display: flex;
  justify-content: flex-end;
  color: var(--contextual-colors-brand-primary);
  line-height: 16px;
  font-size: rem(12);
  font-weight: var(--font-weight-bold);
  padding: 2px 12px 0 0;
}

.snackBar {
  @include devices(sm-up) {
    max-width: 520px;
  }
}

.toMyProfile {
  flex-shrink: 0;
  color: var(--contextual-colors-brand-primary);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
}

.closeAction {
  position: absolute;
  top: 14px;
  right: 16px;

  @include devices(sm-up) {
    position: static;
    cursor: pointer;
    margin-right: spacing(3);
  }
}

.errorTitle {
  font-weight: var(--font-weight-medium);
  font-size: rem(19);
  line-height: 25px;
}

.errorText {
  font-weight: var(--font-weight-medium);
  font-size: rem(16);
  line-height: 21px;
}

.errorIcon {
  width: 65px;
  height: 65px;
}
