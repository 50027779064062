@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  max-width: 400px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25vh;
}

.logo {
  margin-bottom: spacing(3);
  margin-left: auto;
  margin-right: auto;
  width: 70px;
  height: 50px;

  @include devices(md-up) {
    width: 95px;
    height: 73px;
  }
}

.title {
  font-size: rem(24);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    font-size: rem(30);
  }
}

.button {
  width: auto;

  @include devices(md-up) {
    min-width: 240px;
  }
}
