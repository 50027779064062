@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.filterLimited {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--common-white);
  border: 1px solid #ababab;
  margin-bottom: spacing(1);
  border-radius: 5px;

  @include devices(md-up) {
    justify-content: center;
    border-right: 1px solid #d8d8d8;
    margin-bottom: 0;
    border-radius: 0;
    border: none;

    &:last-of-type {
      border-right: none;
    }

    // Need to achieve paddings as per design.
    &:first-of-type {
      padding-left: spacing(2);

      :global(.MuiSelect-root) {
        padding-left: spacing(2);
      }

      :global(.MuiFormLabel-root) {
        left: spacing(2);
      }
    }
  }
}

.filterFull {
  @include devices(md-up) {
    margin-bottom: spacing(4);
  }
}

.pageConfig {
  @include devices(md-up) {
    border-right: 1px solid #ababab;

    &:last-of-type {
      border-right: none;
    }
  }
}
