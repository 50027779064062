@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

$MIN_HEIGHT: 68px;

.content {
  padding: 0;
  padding-right: spacing(2);
  min-height: $MIN_HEIGHT;
}

.photo {
  width: auto;
  height: $MIN_HEIGHT;
  object-fit: cover;
  object-position: center;
}
