@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-15px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(-15px) rotate(-360deg);
  }
}

.root {
  background-color: var(--common-white);
  justify-content: flex-start;
}

.centered {
  justify-content: center;
}

.withPadding {
  padding-top: spacing(6);
}

.icon {
  margin-bottom: spacing(4);
  animation: circle 1s infinite linear;
}

.iconSmall {
  width: 60px;
  height: 60px;

  @include devices(md-up) {
    width: 100px;
    height: 100px;
  }
}

.iconMedium {
  width: 80px;
  height: 80px;
}

.iconBig {
  width: 100px;
  height: 100px;
}

.text {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  margin-top: 0;
  margin-bottom: spacing(4);
}
