@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;
@use "./variables.module.scss" as *;

.root {
  padding: spacing(10, 5);
  text-align: center;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-primary);
}

.isExtended {
  background-color: var(--common-white);
  border-radius: spacing(1);
  max-width: 100%;
  padding: spacing(5, 5);

  .action {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
  font-size: rem(17);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-bottom: spacing(2);
}

.titleError {
  color: var(--error-main);
  font-weight: var(--font-weight-medium);
}

.text {
  font-size: rem(17);
  line-height: 1.5;
  margin: 0;
}

.action {
  display: block;
}

.row {
  margin-bottom: spacing(4);
}

.icon {
  display: block;
  width: 50px;
  height: 50px;
  color: var(--error-main);
  margin-bottom: spacing(2.5);
  margin-left: auto;
  margin-right: auto;
}

.rootEmpty {
  padding: spacing(6, 3);
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.isExtendedEmpty {
  background-color: var(--common-white);
  border-radius: spacing(1);
  max-width: 100%;
  padding: spacing(5, 5);
}

.textEmpty {
  color: var(--text-primary);
  text-align: center;
  font-size: rem(17);
}

.titleEmpty {
  margin: 0;
  margin-bottom: spacing(1.5);
}

.description {
  font-size: rem(17);
}

.favOffset {
  margin-bottom: spacing(5);

  @include devices(s-down) {
    margin-bottom: spacing(10);
  }
}

.arrow {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;

  @include devices(sm-up) {
    bottom: $FOOTER_HEIGHT;
  }
}

.recentsOffset {
  margin-bottom: spacing(5);
}
