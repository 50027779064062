@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.expertWrapper {
  &:not(:last-child) {
    margin-bottom: 0;
    border-bottom: 5px solid var(--grey-100);
  }

  @include devices(md-down) {
    &:first-child {
      margin-top: spacing(2);
    }
  }
}

.linkClassName {
  padding-left: spacing(1.5);

  @include devices(md-up) {
    padding-left: spacing(7.5);
  }
}
