@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.reservationListWrapper {
  padding: spacing(0, 7);
  margin-bottom: spacing(2);

  @include devices(md-down) {
    padding: spacing(0, 1.5);
  }
}

.list {
  padding: 0;
  margin: spacing(0);
  display: flex;
}

.twoItemsList {
  @include devices(md-down) {
    overflow: scroll;
  }
}

.listItem {
  padding: spacing(0);
  margin-bottom: 0;

  &:not(:last-child) {
    padding-right: spacing(2);
  }
}

.listItemSlider {
  @include devices(md-down) {
    padding: spacing(0, 5);
  }

  @include devices(sm-down) {
    padding: 0;
  }
}

.iconHeadlineContainer {
  padding: spacing(2, 1, 0);
  display: flex;
  align-items: center;
  background-color: var(--common-white);
}

.clockIconWrapper {
  height: 16px;
}

.headline {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  line-height: 18px;
  margin-left: spacing(1);
}
