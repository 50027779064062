@use "@styles/partials/functions" as *;

.root {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  ul {
    display: none;
  }

  .listTitle {
    display: none;
  }

  li {
    flex-shrink: 0;

    &.active {
      a {
        color: var(--primary-main);
        font-weight: var(--font-weight-medium);
        border: 1px solid var(--primary-main);
        background-color: var(--action-selected);
      }
    }
  }

  a {
    padding: spacing(1, 1.5);
    color: var(--text-primary);
    font-size: rem(14);
    background-color: var(--common-white);
    margin-right: spacing(1);
    display: inline-block;
    border-radius: 6px;
  }
}

.listTitle {
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  margin-bottom: spacing(2);
}

.chip {
  flex-shrink: 0;
  padding: spacing(1, 1.5);
  color: var(--text-primary);
  font-size: rem(14);
  background-color: var(--common-white);
  margin-right: spacing(1);
  display: inline-block;
  border-radius: 6px;

  &.active {
    color: var(--primary-main);
    font-weight: var(--font-weight-medium);
    border: 1px solid var(--primary-main);
    background-color: var(--action-selected);
  }
}

.tag {
  margin-left: spacing(1);
}
