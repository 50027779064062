@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.headline {
  font-size: rem(14);
  line-height: 18px;
  font-weight: var(--font-weight-medium);

  @include devices(sm-up) {
    font-size: rem(16);
    line-height: 21px;
    margin-bottom: spacing(2);
  }
}

.paperBody {
  padding: spacing(2, 1.5, 0);
  margin-bottom: spacing(2);

  @include devices(sm-up) {
    padding: spacing(3, 7.5, 4, 7.5);
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: left;

  @include devices(md-down) {
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--common-white);
    padding: 12px 24px 34px;
    z-index: 10;
  }
}

.button {
  width: 300px;
  height: 40px;
  margin-top: spacing(2);
}

.checkmarkWrapper {
  margin-right: spacing(1);
}
