@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ctaWrapper {
  background-color: var(--common-white);
  margin: 0;
  display: flex;
  padding: spacing(0.5, 1.5, 2);

  @include devices(md-up) {
    padding: spacing(0, 7.5, 2);
  }
}

.ctaButton {
  margin-left: spacing(0.5);
  margin-right: spacing(0.5);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @include devices(sm-up) {
    max-width: 140px;
  }
}

.badgeRoot {
  margin-right: spacing(0.5);

  @include devices(sm-up) {
    margin-right: spacing(1);
  }
}

.promotionRoot {
  height: 70px;

  width: 100%;
  margin-top: spacing(2);

  @include devices(sm-up) {
    width: 320px;
  }
}
