@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.subHeadline {
  @include devices(md-up) {
    max-width: 302px;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  padding: 0;

  @include devices(md-up) {
    padding-right: spacing(2.875);
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: spacing(6);

  @include devices(md-up) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    justify-content: center;
  }
}

.rightColumnAlternate {
  margin-top: spacing(1);

  @include devices(md-up) {
    margin-top: 0;
  }
}

.linkCollage {
  margin-top: spacing(3);
  min-width: 100%;

  @include devices(md-up) {
    margin-top: spacing(5);
    margin-bottom: spacing(2);
  }
}
