@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  @include devices(md-up) {
    display: inline-flex;
    position: relative;
    justify-content: center;
    transition: background 0.3s ease-in, border 0.3s ease-in;
    background: transparent;
    align-items: center;
    border: 1px solid transparent;

    &:hover {
      background: var(--contextual-colors-bg-secondary-accent);
      border: 1px solid var(--grey--a200);
      border-bottom-color: var(--contextual-colors-bg-secondary-accent);

      .dropdown {
        max-height: 1000px;
        z-index: 1;
      }

      .subMenuOpener {
        transform: rotate(180deg);
      }
    }
  }
}

.menuItemOpen {
  background: var(--contextual-colors-bg-main);
}

.menuLinkWrapper {
  position: relative;
  width: 100%;

  @include devices(md-up) {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.menuLink {
  display: block;
  width: 100%;
  font-size: rem(16);
  line-height: 1.5;
  position: relative;
  padding: spacing(1.5, 2);
  transition: background 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);

  @include devices(md-up) {
    font-weight: var(--font-weight-medium);
    padding: spacing(0, 4.5, 0, 3);
    cursor: pointer;
    display: flex;
    white-space: nowrap;
  }
}

.subMenuOpenerWrapper {
  position: absolute;
  width: 35%;
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  z-index: 1;
  right: 10px;
  cursor: pointer;
  top: 0;
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  -webkit-tap-highlight-color: transparent;

  @include devices(md-up) {
    width: auto;
    top: 0;
    pointer-events: none;
  }
}

.subMenuOpener {
  transition: transform 0.25s;
}

.subMenuOpenerOpened {
  transform: rotate(180deg);
}

.dropdown {
  width: 100%;
  max-height: 0;
  transition: max-height 0.3s ease-in;
  overflow: hidden;

  @include devices(md-up) {
    min-width: 216px;
    width: auto;
    position: absolute;
    top: 100%;
    left: -1px;
    margin-top: -1px;
    z-index: -1;
  }
}

.dropdownOpen {
  max-height: 1000px;
}

.subMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: var(--contextual-colors-bg-main);
  font-weight: var(--font-weight-medium);

  @include devices(md-up) {
    width: auto;
    min-width: 216px;
    padding: spacing(3.5, 0);
    border-radius: 0 0 4px 4px;
    background: linear-gradient(
      180.41deg,
      var(--contextual-colors-bg-secondary-accent) 0.5%,
      var(--contextual-colors-negative) 52.85%
    );
    font-weight: var(--typography-fontWeightRegular);
    border: 1px solid var(--grey--a200);
  }
}

.subMenuLink {
  position: relative;
  width: 100%;
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
  display: block;
  -webkit-text-size-adjust: none;
  font-size: rem(14);
  line-height: 18px;
  padding: spacing(1.25, 2, 1.25, 4);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);

  &:before {
    display: block;
    position: absolute;
    background-color: var(--primary-main);
    border-radius: 3px;
    width: 5px;
    height: 20px;
    left: spacing(2);
    top: 50%;
    margin-top: -10px;

    @include devices(md-up) {
      left: spacing(1.5);
    }
  }

  &.highlight:before {
    content: "";
  }

  &:hover {
    transition: color 0.3s;
    color: var(--primary-main);
  }

  @include devices(md-up) {
    font-weight: var(--font-weight-regular);
    font-size: rem(16);
    line-height: 30px;
    padding: spacing(0, 3);
    white-space: nowrap;
  }
}

.tag {
  margin-left: spacing(1);
}

.bold {
  font-weight: var(--font-weight-medium);
}
