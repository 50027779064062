@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.labelLimited {
  font-size: rem(14);
}

.labelFull {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: spacing(2);
}

.radioLabel {
  font-size: rem(15);
  font-weight: var(--font-weight-regular);
  color: var(--text-primary);
}

.radioLabelTypeExpanded {
  @include devices(md-down) {
    font-size: rem(16);
    font-weight: var(--font-weight-medium);
    color: var(--contextual-colors-ui-secondary);
  }
}

.radio {
  svg {
    width: 16px;
    height: 16px;
  }
}

.radioTypeExpanded {
  @include devices(md-down) {
    border-bottom: 1px solid var(--contextual-colors-ui-secondary);
    margin: 0;
    margin-left: spacing(4);
    padding-top: spacing(1);
    padding-bottom: spacing(1);

    svg {
      padding: 0;
      display: none;
    }
  }
}

.radioButtonChecked {
  @include devices(md-down) {
    & + span {
      color: var(--primary-main);
    }
  }
}
