@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  border-radius: 4px;
  background-color: var(--common-white);
}

.isSticky {
  position: sticky;
  top: calc(64px + spacing(2));
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    position: relative;
    padding: spacing(1.5, 4);
    font-size: rem(14);
    font-weight: var(--font-weight-bold);

    &:hover {
      color: var(--primary-main);
      transition: color 0.3s ease;
    }

    &:before {
      display: block;
      position: absolute;
      background-color: var(--primary-main);
      border-radius: 3px;
      width: 5px;
      height: 20px;
      left: spacing(1.5);
      top: 50%;
      margin-top: -10px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      border-top: 1px solid var(--contextual-colors-ui-secondary);
      border-right: 1px solid var(--contextual-colors-ui-secondary);
      transform: rotate(45deg);
      width: 14px;
      height: 14px;
      right: spacing(1.5);
      top: 50%;
      margin-top: -7px;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    + ul {
      display: none;
    }
  }

  li {
    &.active {
      > a + {
        ul {
          display: block;
        }
      }
    }

    &.highlight {
      > a {
        &:before {
          content: "";
        }
      }
    }

    li {
      padding-left: spacing(1.5);

      a {
        font-weight: var(--font-weight-regular);
      }
    }
  }
}

.listTitle {
  display: block;
  padding: spacing(1.5, 4);
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  border-bottom: 1px solid var(--contextual-colors-neutral);
  color: var(--text-secondary);
}

.tag {
  margin-left: spacing(1);
}
