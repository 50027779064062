@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.tabClassName {
  padding: spacing(1, 1, 0.5, 1);
  min-height: 0;
  text-transform: none;
  font-size: rem(14);

  &:disabled {
    color: var(--primary-main);
  }

  @include devices(md-up) {
    font-size: rem(16);
    line-height: rem(24);
    padding: spacing(1.5, 1, 0.5, 1);
  }
}
