@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.buttonWrapper {
  position: relative;

  .statusIndicator {
    min-width: 16px;
    min-height: 16px;
    border-width: 0;
    transform: none;
    left: calc(100% - 17px);
    top: 0;
    right: auto;
    font-size: rem(12);
    font-weight: var(--font-weight-medium);
    line-height: 1em;
    color: var(--common-white);

    @include devices(md-up) {
      left: calc(100% - 14px);
      top: -4px;
    }
  }
}

.root {
  display: flex;
  margin: 0;
  padding: 0;

  & > li {
    padding: spacing(0, 0, 0, 2);

    &:first-child {
      padding: spacing(0);
    }
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  color: inherit;
  padding: 4px;
  box-shadow: none;
  background-color: transparent;

  @include devices(md-up) {
    border: 1px solid;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.active {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
  color: var(--common-white);

  &:hover {
    background-color: var(--primary-main);
  }
}

.isTransparent {
  @include devices(md-up) {
    border: 1px solid;

    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }

    &.active {
      border-color: var(--primary-main);
      background-color: var(--primary-main);
    }
  }
}

.iconLabelClassName {
  display: flex;
  position: absolute;
}

.statusPlaceholder {
  position: absolute;
  top: 0;
  right: 1px;

  @include devices(md-up) {
    top: -4px;
    right: -2px;
  }

  & :global(.MuiSkeleton-root) {
    background-color: rgb(0, 0, 0, 0.4);
  }
}

.myExperts {
  z-index: 100;
  width: 28vw;
  min-width: 380px;
  max-width: 420px;
  position: absolute;
  top: 60px;
  right: 0;
}
