@use "@styles/partials/functions" as *;
@use "./variables.module.scss" as *;

.root {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--common-white);
  overflow-y: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(2, 2, 0.5, 2);
}

.tabBody {
  max-height: calc(100vh - $HEADER_HEIGHT - $DESKTOP_TAB_HEADER_HEIGHT - 120px);
  overflow-y: auto;

  &.shouldFooterRender {
    max-height: calc(100vh - $HEADER_HEIGHT - $DESKTOP_TAB_HEADER_HEIGHT - $FOOTER_HEIGHT - 120px);
  }
}

.headerText {
  font-weight: var(--font-weight-bold);
  font-size: rem(19);
  line-height: 25.4px;
  color: var(--text-primary);
}

.closeIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: var(--text-primary);
}
