@use "@styles/partials/functions" as *;

.root {
  position: relative;
  background-color: var(--common-white);
  border-radius: 4px;
  width: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: calc(100vh - 32px);
}

.steps {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  :global(.react-swipeable-view-container) {
    flex-grow: 1;
  }
}

// fix for dynamic content on last step
.isLastStep {
  :global(.react-swipeable-view-container) {
    height: 100% !important;
  }
}

.step {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.hide {
  display: none;
}
