@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.stepperRoot {
  width: 100%;
  background-color: transparent;
  padding: spacing(1.5, 1);
  margin-bottom: spacing(3);

  @include devices(md-up) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: spacing(6);
    margin-top: spacing(6);
  }
}

.dots {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  margin-left: spacing(0.5);
  margin-right: spacing(0.5);
  background-color: var(--primary-main);

  &.dotActive ~ * {
    background-color: var(--contextual-colors-neutral);
  }

  @include devices(md-up) {
    width: 100%;
    border-radius: 8px;
  }
}

.swiperAction {
  padding: spacing(1);
  z-index: 10;
  width: 28px;
  height: 28px;
  transform: translateY(68px);
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
  box-shadow: none;

  &[disabled] {
    opacity: 0.3;
  }

  @include devices(md-up) {
    position: relative;
    top: auto;
    width: auto;
    height: auto;
    transform: none;
    padding: spacing(0.5);
    border-radius: 8px;
  }
}

.swiperBackAction {
  left: 0;
  transform: scaleX(-1) translateY(68px);

  @include devices(md-up) {
    transform: none;
    left: auto;
  }
}

.swiperNextAction {
  right: 0;

  @include devices(md-up) {
    right: "auto";
  }
}

.swiperActionArrowForward {
  height: 18px;
  margin-left: spacing(0.5);
}

.swiperActionArrowBack {
  height: 18px;
  margin-right: spacing(0.5);
}
