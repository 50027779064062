@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  flex-direction: column;

  @include devices(md-up) {
    flex-direction: row;
  }
}

.navItem {
  border-bottom: 1px solid var(--contextual-colors-neutral);

  &:last-child {
    border-bottom: none;
  }

  @include devices(md-up) {
    border: none;
  }
}
