@use "@styles/partials/functions" as *;

.avatarWrapper {
  width: 53px;
}

.name {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  margin: 0;
  color: var(--contextual-colors-text-body);
}
