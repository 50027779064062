@use "@styles/partials/breakpoints" as *;

.wave {
  position: fixed;
  z-index: -10;
  bottom: 0;
  max-height: 40%;
}

.blue {
  transition: left 0.3s ease;
  bottom: -20px;

  @include devices(md-up) {
    bottom: -50px;
  }
}

.lightBlue {
  transition: left 0.3s ease;
  bottom: -10px;
}
