@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.formField {
  text-align: left;
  margin-bottom: spacing(2);

  &:last-child {
    margin-bottom: 0;
  }
}

.newsletterOptionLabel {
  align-items: flex-start;
}

.newsletterOptionText {
  font-weight: 500;
  color: var(--contextual-colors-ui-secondary);
  line-height: normal;
  @include devices(md-up) {
    font-size: rem(16);
  }
}

.newsletterOptionIcon {
  margin-top: spacing(-1.5);
}

.formLink {
  font-size: rem(12);
  font-weight: 500;
  color: var(--contextual-colors-ui-secondary);
  @include devices(md-up) {
    font-size: rem(14);
  }
}

.link {
  color: var(--primary-main);
  padding-left: spacing(0.5);
  padding-right: spacing(0.5);
}
