@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.heroImage {
  background-repeat: no-repeat;
  background-size: cover;
  height: 205px;
  width: 100%;

  @include devices(sm-up) {
    height: 303px;
  }

  @include devices(md-up) {
    height: 400px;
  }
}

.container {
  height: 100%;
  min-height: inherit;
}

.textShadow {
  & > * {
    text-shadow: 0 1px 2px rgba(64, 64, 65, 0.6);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: inherit;
  margin: auto;
  padding-left: spacing(2);
  padding-right: spacing(2);

  & > h1 {
    font-size: rem(24);
    margin-bottom: 0.5em;

    @include devices(sm-up) {
      font-size: rem(32);
    }
  }

  & > h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: rem(16);

    @include devices(sm-up) {
      font-size: rem(20);
    }
  }

  /** Fix for trailing paragraph in rich text editor.
   *
   * https://github.com/contentful/rich-text/issues/101 */
  & > p:empty {
    display: none;
  }

  @include devices(md-up) {
    padding-left: spacing(10);
    padding-right: spacing(10);
  }
}
