@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.title {
  margin: 0;
}

.text {
  margin: 0;
  font-size: rem(14);
}

.fontCommon {
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
}

.linkCta {
  padding: spacing(0.75, 1.75);
}

.cta {
  width: 100%;
  min-width: 100px;

  @include devices(sm-up) {
    width: auto;

    &:not(.widthForContainerQuery) {
      width: 100%;
    }
  }
}

.root {
  height: 100%;
  padding: spacing(2);
  background-color: var(--contextual-colors-negative);
  border-radius: 4px;
  text-align: center;

  @include devices(sm-up) {
    height: auto;

    &:not(.widthForContainerQuery) {
      padding: spacing(3);
    }
  }
}

.hr {
  height: 1px;
  width: 100%;
  border: none;
  background-color: var(--contextual-colors-neutral);
  margin-top: spacing(2);
  margin-bottom: 0;
}
