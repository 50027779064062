@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.portal {
  display: none;

  @include devices(md-up) {
    display: block;
  }
}
