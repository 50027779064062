@use "@styles/partials/functions" as *;

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.listItem {
  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
