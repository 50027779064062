@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 25vh;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-left: spacing(2);
  padding-right: spacing(2);
}

.title {
  font-size: rem(24);
  font-weight: var(--font-weight-bold);
  padding-left: spacing(4);
  padding-right: spacing(4);
  margin-top: 0;

  @include devices(md-up) {
    font-size: rem(30);
  }
}
