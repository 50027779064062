@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  flex-grow: 1;
}

.group {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @include devices(md-up) {
    flex-wrap: nowrap;
  }
}

.button {
  border: none !important;
  padding: spacing(1);
  color: var(--primary-main);
  margin-bottom: spacing(3);
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: rem(14);
  line-height: 1.3;
  font-weight: var(--font-weight-medium);
  text-transform: none;
  color: var(--text-primary);

  @include devices(md-up) {
    font-size: rem(20);
    line-height: rem(24);
  }
}

.icon {
  width: 46px;
  height: 46px;
  margin-bottom: spacing(2);

  @include devices(md-up) {
    width: 100px;
    height: 100px;
  }
}

.active {
  color: var(--primary-main);
}

.productType {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .button {
    font-size: rem(30);

    flex-grow: 1;
    text-align: center;
    margin-left: spacing(1);
    margin-right: spacing(1);
    padding: spacing(1.5);
    border-radius: 10px;
    color: var(--common-white);
  }

  .label {
    font-size: rem(16);
    color: var(--common-white);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);

    @include devices(md-up) {
      font-size: rem(30);
    }
  }

  .icon {
    display: flex;
    width: 28px;
    height: 28px;
    margin-bottom: 0;

    @include devices(md-up) {
      width: 46px;
      height: 46px;
    }
  }
}

.method {
  .button {
    width: calc(100% / 3);
  }
}

.topic {
  .button {
    width: calc(100% / 2);
  }
}
