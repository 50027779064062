@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.sticky {
  z-index: 10;
  height: auto;

  @include devices(md-down) {
    padding: spacing(1, 2, 2);
    background-color: var(--common-white);
    box-shadow: 0 10px 20px 0 rgba(147, 147, 147, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
