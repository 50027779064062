@use "@styles/partials/breakpoints" as *;

.root {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @include devices(md-up) {
    max-width: 900px;
  }
}
