@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

$item-count-in-row: 3;
$nth-number: $item-count-in-row + 1;

.stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: spacing(4);

  &:first-child {
    margin-top: 0;
  }

  @include devices(sm-up) {
    margin-top: 0;
    max-width: calc(100% / #{$item-count-in-row} - 1%);

    &:nth-child(n + #{$nth-number}) {
      margin-top: spacing(6);
    }
  }
}

.itemHeadline {
  margin-top: spacing(1);
  margin-bottom: spacing(1);
  transition: color 0.3s;

  @include devices(sm-up) {
    margin-top: spacing(2);
  }
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  width: 100%;
}

.itemSubHeadline {
  font-size: rem(16);
  transition: color 0.3s;
  width: 100%;
}

.image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.link {
  &:hover {
    .itemHeadline,
    .itemSubHeadline {
      color: var(--primary-dark);
    }
  }
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subheadline {
  max-width: 840px;
  text-align: center;
  > p {
    margin: 0;
  }
}

.stackContainer {
  justify-content: space-between;
  margin-top: spacing(4);

  @include devices(sm-up) {
    margin-top: spacing(7.5);
  }
}
