@import "@styles/partials/breakpoints";
@import "@styles/partials/functions";

.container {
  @include devices(sm-up) {
    padding-top: spacing(3);
  }
}

.trustSection {
  display: none;

  @include devices(sm-up) {
    display: block;
  }
}
