@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.drawerAppBar.drawerAppBar {
  flex: none;
}

.drawerAppBarContainer {
  padding: spacing(0, 2);
}

.spacer {
  @include devices(lg-down) {
    &:first-child {
      margin-left: -12px;
    }
  }
}

.userArea {
  border-bottom: 1px solid var(--contextual-colors-neutral);
}

.searchContainer {
  width: 34px;
  line-height: 0;
}

.searchContainerOpened {
  position: absolute;
  width: 100%;
  background: var(--common-white);
  left: 0;
  top: 0;
  z-index: 1;
  padding: 14px 16px;
}

.actionsWrapper {
  display: inline-flex;
  min-width: 34px;
}
