@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.footer {
  background-color: var(--contextual-colors-bg-footer);
  color: var(--common-white);
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: spacing(4);
  padding-bottom: spacing(4);

  @include devices(md-up) {
    padding-top: spacing(6);
    padding-bottom: spacing(6);
  }
}

.mainText {
  text-align: center;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    margin-bottom: spacing(2);
    font-size: rem(16);
  }
}
