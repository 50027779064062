@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.filter {
  background-color: transparent !important;
  color: var(--text-primary);
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  margin-top: 0 !important;

  :global(.MuiOutlinedInput-notchedOutline) {
    border-width: 1px !important;
    border-color: var(--contextual-colors-ui-secondary);
  }

  @include devices(md-up) {
    font-size: rem(16);
    line-height: rem(20);
  }
}

.selectedOption {
  background-color: transparent !important;
  padding: spacing(1.25, 2, 1.25, 1);

  @include devices(md-up) {
    font-weight: var(--font-weight-regular);
  }
}

.filterSorting {
  .selectedOption {
    padding: spacing(3, 2, 0.75, 2);
  }
}

.filterLimited {
  color: var(--text-primary);
  font-size: rem(16);
  font-weight: var(--font-weight-medium);

  .selectedOption {
    padding: spacing(3, 2, 1, 2);
  }

  @include devices(md-up) {
    .selectedOption {
      padding: spacing(5, 4, 2, 2);
    }
  }
}

.labelLimited {
  position: absolute;
  left: spacing(2);
  top: spacing(1.25);
  font-size: rem(12);
  font-weight: var(--font-weight-regular);
  color: var(--text-secondary) !important;

  @include devices(md-up) {
    top: spacing(2);
    left: spacing(4);
    font-size: rem(14);
  }
}

.labelFull {
  @include devices(md-up) {
    font-size: rem(16);
    font-weight: var(--font-weight-bold);
    color: var(--common-black) !important;
    margin-bottom: spacing(2);
  }
}

.labelFullSorting {
  position: absolute;
  left: spacing(2);
  top: spacing(0.75);
  font-size: rem(14);
  font-weight: var(--font-weight-regular);
  color: var(--text-secondary) !important;
}

.wrapperFullSorting {
  position: relative;
  border-radius: 5px;
}

.selectMenu {
  margin-top: spacing(1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  max-height: 210px;
}

.selectMenuLimited {
  margin-top: 1px;
}

.selectIcon {
  transition: transform 0.05s ease;
  color: var(--contextual-colors-ui-secondary);
}

.selectIconLimited {
  top: 15px;
  right: spacing(2);

  @include devices(md-up) {
    top: 30px;
    right: spacing(3.5);
  }
}

.selectIconSorting {
  right: spacing(1);
  top: 15px;
}

.selectIconOpen {
  color: var(--primary-main);
}
