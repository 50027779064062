@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  flex: 1 0 auto;
  margin-top: spacing(3);
  margin-bottom: spacing(3);
  padding: 0;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileHidden {
  @include devices(md-down) {
    display: none;
  }
}
